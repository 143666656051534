<template>
  <div>
    <validation-observer
      ref="accountRules"
      tag="form"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Data Prestasi
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tanggal Event"
              label-for="date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-input
                  id="date"
                  v-model="dataPrestasi.date"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  placeholder="21-05-1992"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nama Event"
              label-for="event"
            >
              <validation-provider
                #default="{ errors }"
                name="event"
                rules="required"
              >
                <b-form-input
                  id="event"
                  v-model="dataPrestasi.event"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="category"
              rules="required"
            >
              <b-form-group
                label="Kategori"
                label-for="category"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="category"
                  v-model="dataPrestasi.category"
                  :reduce="(category) => category.value"
                  placeholder="Pilih Kategori"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataCategory"
                  label="text"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Penghargaan"
              label-for="achivement"
            >
              <validation-provider
                #default="{ errors }"
                name="achivement"
                rules="required"
              >
                <b-form-input
                  id="achivement"
                  v-model="dataPrestasi.achivement"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Juara I"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="formSubmitted"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataPrestasi: {
        date: '',
        event: '',
        category: '',
        achivement: '',
      },
      // selectedContry: '',
      // selectedLanguage: '',
      // name: '',
      // emailValue: '',
      // PasswordValue: '',
      // passwordCon: '',
      // first_name: '',
      // last_name: '',
      // address: '',
      // landMark: '',
      // pincode: '',
      // twitterUrl: '',
      // facebookUrl: '',
      // googleUrl: '',
      // linkedinUrl: '',
      // city: '',
      // required,
      // email,
      // codeIcon,
      dataCategory: [
        { value: 1, text: 'Game' },
        { value: 2, text: 'Race' },
        { value: 3, text: 'Turnamen Category' },
      ],
    }
  },
  computed: {
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormPreastasi = new FormData()
        Object.keys(this.dataPrestasi).forEach(key => {
          dataFormPreastasi.append(key, this.dataPrestasi[key])
        })
        await this.$http.post('/achivements', dataFormPreastasi)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Prestasi Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-prestasi' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Prestasi Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
